export const routerLaporan = [
  {
    path: '/laporan',
    meta: { title: 'Laporan' },
    component: () => import('./views/laporan'),
  },
  {
    path: '/laporan/kelola-laporan',
    meta: { title: 'Kelola Laporan' },
    component: () => import('./views/laporan/kelola-laporan'),
  },
  {
    path: '/laporan/lihat-pemetaan-gudang',
    meta: { title: 'Lihat Pemetaan Gudang' },
    component: () => import('./views/laporan/lihat-pemetaan-gudang'),
  },
  {
    path: '/laporan/laporan-penjualan',
    meta: { title: 'Laporan Penjualan' },
    component: () => import('./views/laporan/laporan-penjualan'),
  },
  {
    path: '/laporan/transaksi-distributor',
    meta: { title: 'Transaksi Distributor' },
    component: () => import('./views/laporan/transaksi-distributor'),
  },
  {
    path: '/laporan/transaksi-gudang',
    meta: { title: 'Transaksi Gudang' },
    component: () => import('./views/laporan/transaksi-gudang'),
  },
  {
    path: '/laporan/performance-brand',
    meta: { title: 'Performance Brand' },
    component: () => import('./views/laporan/performance-brand'),
  },
  {
    path: '/laporan/transaksi-toko',
    meta: { title: 'Laporan Transaksi Bulanan ID Toko' },
    component: () => import('./views/laporan/transaksi-toko'),
  },
  {
    path: '/laporan/konfigurasi-loyalti',
    meta: { title: 'Konfigurasi Loyalti' },
    component: () => import('./views/laporan/konfigurasi-loyalti'),
  },
  {
    path: '/laporan/gpm-per-gudang',
    meta: { title: 'GPM per Gudang' },
    component: () => import('./views/laporan/gpm-per-gudang'),
  },
  {
    path: '/laporan/gpm-per-distributor',
    meta: { title: 'GPM per Distributor' },
    component: () => import('./views/laporan/gpm-per-distributor'),
  },
  {
    path: '/laporan/gpm-per-produk',
    meta: { title: 'GPM per Produk' },
    component: () => import('./views/laporan/gpm-per-produk'),
  },
  {
    path: '/laporan/performance-gudang',
    meta: { title: 'Performance Gudang' },
    component: () => import('./views/laporan/performance-gudang'),
  },
  {
    path: '/laporan/laporan-loyalti-poin',
    meta: { title: 'Laporan Loyalti Poin' },
    component: () => import('./views/laporan/laporan-loyalti-poin'),
  },
  {
    path: '/laporan/laporan-transaksi-toko-harian',
    meta: { title: 'Laporan Transaksi ID Toko' },
    component: () => import('./views/laporan/laporan-transaksi-toko-harian'),
  },
  {
    path: '/laporan/laporan-kontrak-volume',
    meta: { title: 'Laporan Kontrak Volume' },
    component: () => import('./views/laporan/laporan-kontrak-volume'),
  },
  {
    path: '/laporan/numerik-distribution',
    meta: { title: 'Numerik Distribution' },
    component: () => import('./views/laporan/numerik-distribution'),
  },
  {
    path: '/laporan/unggah-program-loyalti',
    meta: { title: 'Unggah Program Loyalti' },
    component: () => import('./views/laporan/unggah-program-loyalti'),
  },
  {
    path: '/laporan/program-up-front',
    meta: { title: 'Program Up Front' },
    component: () => import('./views/laporan/program-up-front'),
  },
  {
    path: '/laporan/transaksi-distributor-2',
    meta: { title: 'Transaksi Distributor 2' },
    component: () => import('./views/laporan/transaksi-distributor-2'),
  },
  {
    path: '/laporan/konfigurasi-program-loyalti',
    meta: { title: 'Konfigurasi Program Loyalti' },
    component: () => import('./views/laporan/konfigurasi-program-loyalti'),
  },
  {
    path: '/laporan/laporan-up-front',
    meta: { title: 'Laporan Up Front' },
    component: () => import('./views/laporan/laporan-up-front'),
  },
  {
    path: '/laporan/laporan-toko-aktif-ca',
    meta: { title: 'Laporan Toko Aktif (CA)' },
    component: () => import('./views/laporan/penjualan/list-toko-aktif'),
  },
  {
    path: '/laporan/laporan-transaksi-toko',
    meta: { title: 'Laporan Transaksi Bulanan ID Toko' },
    component: () => import('./views/laporan/laporan-transaksi-toko'),
  },
  {
    path: '/laporan/monitor-transaksi-toko',
    meta: { title: 'Monitor Transaksi Toko' },
    component: () => import('./views/laporan/monitor-transaksi-toko'),
  },
  {
    path: '/laporan/laporan-detail-transaksi-gudang-distributor',
    meta: { title: 'Laporan Detail Transaksi Gudang Distributor' },
    component: () => import('./views/laporan/laporan-detail-transaksi-gudang-distributor'),
  },
  {
    path: '/laporan/laporan-transaksi-distributor',
    meta: { title: 'Laporan Transaksi Distributor' },
    component: () => import('./views/laporan/laporan-transaksi-distributor'),
  },
  {
    path: '/laporan/laporan-stok-distributor',
    meta: { title: 'Laporan Stok Distributor' },
    component: () => import('./views/laporan/laporan-stok-distributor'),
  },
  {
    path: '/laporan/monitor-volume-distributor',
    meta: { title: 'Monitor Volume Distributor' },
    component: () => import('./views/laporan/monitor-volume-distributor'),
  },
  {
    path: '/laporan/data-toko-noo',
    meta: { title: 'Data Toko Noo' },
    component: () => import('./views/laporan/penjualan/data-toko-noo'),
  },
  {
    path: '/laporan/data-toko-noo-new',
    meta: { title: 'Data Toko Noo New' },
    component: () => import('./views/laporan/penjualan/data-toko-noo-new'),
  },
  {
    path: '/laporan/laporan-transaksi-per-brand',
    meta: { title: 'Laporan Grafik Per-Brand' },
    component: () => import('./views/laporan/laporan-transaksi-per-brand'),
  },
  {
    path: '/laporan/laporan-transaksi-harian-gudang',
    meta: { title: 'Laporan Grafik Harian Gudang' },
    component: () => import('./views/laporan/laporan-transaksi-harian-gudang'),
  },
  {
    path: '/laporan/log-api',
    meta: { title: 'Log Api' },
    component: () => import('./views/laporan/log-api'),
  },
  {
    path: '/laporan/log-data-msc',
    meta: { title: 'Log Data MSC' },
    component: () => import('./views/laporan/log-data-msc'),
  },
  {
    path: '/laporan/master-api-distributor',
    meta: { title: 'Master Api Distributor' },
    component: () => import('./views/laporan/master-api-distributor'),
  },
  {
    path: '/laporan/master-data-toko',
    meta: { title: 'Master Data Toko' },
    component: () => import('./views/laporan/master-data-toko'),
  },
  {
    path: '/laporan/grafik-gudang',
    meta: { title: 'Grafik Gudang' },
    component: () => import('./views/laporan/grafik-gudang'),
  },
  {
    path: '/laporan/monitoring-sidigi-mdxl',
    meta: { title: 'Monitoring Sidigi MDXL' },
    component: () => import('./views/laporan/monitoring-sidigi-mdxl'),
  },
  {
    path: '/laporan/monitor-transaksi-toko-harian',
    meta: { title: 'Monitor Transaksi Toko Harian' },
    component: () => import('./views/laporan/monitor-transaksi-toko-harian'),
  },
  {
    path: '/laporan/monitor-gudang-distributor',
    meta: { title: 'Monitor Gudang Distributor' },
    component: () => import('./views/laporan/monitor-gudang-distributor'),
  },
  {
    path: '/laporan/level-stock',
    meta: { title: 'Potensi Penjualan Detail' },
    component: () => import('./views/laporan/level-stock'),
  },
  {
    path: '/laporan/potensi-penjualan-distrik-distributor',
    meta: { title: 'Potensi Penjualan Per Distrik & Distributor' },
    component: () => import('./views/laporan/potensi-penjualan-distrik-distributor'),
  },
  {
    path: '/laporan/potensi-penjualan-per-distrik',
    meta: { title: 'Potensi Penjualan Per Distrik' },
    component: () => import('./views/laporan/potensi-penjualan-per-distrik'),
  },
  {
    path: '/laporan/potensi-penjualan-per-distributor',
    meta: { title: 'Potensi Penjualan Per Distributor' },
    component: () => import('./views/laporan/potensi-penjualan-per-distributor'),
  },
  {
    path: '/laporan/laporan-detail-transaksi-toko',
    meta: { title: 'Laporan Detail Transaksi Toko' },
    component: () => import('./views/laporan/laporan-detail-transaksi-toko'),
  },
  {
    path: '/laporan/laporan-detail-transaksi-toko-v2',
    meta: { title: 'Laporan Detail Transaksi Toko V2' },
    component: () => import('./views/laporan/laporan-detail-transaksi-toko-v2'),
  },
  {
    path: '/laporan/laporan-transaksi-harian-aksestoko',
    meta: { title: 'Laporan Transaksi Harian Aksestoko' },
    component: () => import('./views/laporan/laporan-transaksi-harian-aksestoko'),
  },
  {
    path: '/laporan/laporan-detail-transaksi-lt',
    meta: { title: 'Laporan Detail Transaksi LT' },
    component: () => import('./views/laporan/laporan-detail-transaksi-lt'),
  },
  {
    path: '/laporan/laporan-detail-transaksi-distributor',
    meta: { title: 'Laporan Detail Transaksi Distributor' },
    component: () => import('./views/laporan/laporan-detail-transaksi-distributor'),
  },
  {
    path: '/laporan/potensi-penjualan-detail',
    meta: { title: 'Potensi Penjualan Detail' },
    component: () => import('./views/laporan/potensi-penjualan-detail'),
  },
  {
    path: '/laporan/gpm-per-distrik',
    meta: { title: 'GPM Perdistrik' },
    component: () => import('./views/laporan/gpm-per-distrik'),
  },
  {
    path: '/laporan/laporan-cluster-toko',
    meta: { title: 'Laporan Cluster Toko' },
    component: () => import('./views/laporan/laporan-cluster-toko'),
  },
  {
    path: '/laporan/detail-transaksi-toko-by-product',
    meta: { title: 'Laporan Detail Transaksi Toko by Product' },
    component: () => import('./views/laporan/detail-transaksi-toko-by-product'),
  },
  {
    path: '/laporan/laporan-id-toko-by-product-distributor',
    meta: { title: 'Laporan ID Transaksi Toko by Product Distributor' },
    component: () => import('./views/laporan/laporan-id-toko-by-product-distributor'),
  },
  {
    path: '/laporan/syncron-sdfc',
    meta: { title: 'Syncron SDFC' },
    component: () => import('./views/laporan/syncron-sdfc'),
  },
  {
    path: '/laporan/laporan-toko-lelang-mapping',
    meta: { title: 'Laporan Suggesion Toko Lelang (Mapping Distributor)' },
    component: () => import('./views/laporan/laporan-toko-lelang-mapping'),
  },
  {
    path: '/laporan/laporan-toko-lelang-lintas-mapping',
    meta: { title: 'Laporan Suggession Toko Lelang (Mapping dan non Mapping Distributor)' },
    component: () => import('./views/laporan/laporan-toko-lelang-lintas-mapping'),
  },
  {
    path: '/laporan/laporan-toko-lelang-non-mapping',
    meta: { title: 'Laporan Suggesion Toko Lelang (Non Mapping Distributor)' },
    component: () => import('./views/laporan/laporan-toko-lelang-non-mapping'),
  },
  {
    path: '/laporan/laporan-suggestion-toko-lelang',
    meta: { title: 'Suggesion Toko Lelang' },
    component: () => import('./views/laporan/laporan-suggestion-toko-lelang'),
  },
  {
    path: '/laporan/laporan-toko-lelang',
    meta: { title: 'Laporan Toko Lelang' },
    component: () => import('./views/laporan/laporan-toko-lelang'),
  },
  {
    path: '/laporan/history-toko-lelang',
    meta: { title: 'History Toko Lelang' },
    component: () => import('./views/laporan/history-toko-lelang'),
  },
  {
    path: '/laporan/monitoring-toko-transaksi',
    meta: { title: 'Monitoring Toko Transaksi' },
    component: () => import('./views/laporan/penjualan/monitoring-toko-transaksi'),
  },
  {
    path: '/laporan/laporan-toko-aktif-ca-admin',
    meta: { title: 'Laporan Toko Aktif (CA) (Admin)' },
    component: () => import('./views/laporan/penjualan/list-toko-aktif-admin.vue'),
  },
  {
    path: '/laporan/laporan-toko-aktif-lt',
    meta: { title: 'Laporan Toko Aktif LT' },
    component: () => import('./views/laporan/penjualan/toko-aktif-lt'),
  },
  {
    path: '/laporan/monitor-toko-belum-transaksi',
    meta: { title: 'Monitor Toko Belum Transaksi' },
    component: () => import('./views/laporan/penjualan/monitor-toko-belum-transaksi'),
  },
  {
    path: '/laporan/raw-data-distributor',
    meta: { title: 'Laporan Raw Data Distributor' },
    component: () => import('./views/laporan/helpdesk/raw-data-distributor'),
  },
  {
    path: '/laporan/forcapos-delivery',
    meta: { title: 'Forcapos Delivery' },
    component: () => import('./views/laporan/helpdesk/forcapos-delivery'),
  },
  {
    path: '/laporan/data-volume-penjualan-distributor',
    meta: { title: 'Data Volume Penjualan Distributor' },
    component: () => import('./views/laporan/distributors/data-volume-penjualan-distributor.vue'),
  },
  {
    path: '/laporan/transaksi-stok-gudang-distributor',
    meta: { title: 'Laporan Transaksi Stok Gudang Distributor' },
    component: () => import('./views/laporan/helpdesk/transaksi-stok-gudang-distributor'),
  },
  {
    path: '/laporan-tim-io/log-stok-gudang-distributor',
    meta: { title: 'Log Stok Gudang Distributor' },
    component: () => import('./views/laporan-tim-io/log-stok-gudang-distributor'),
  },
  {
    path: '/laporan-aksestoko/all-transaksi',
    meta: { title: 'Laporan All Transaksi' },
    component: () => import('./views/laporan-aksestoko/all-transaksi.vue'),
  },
  {
    path: '/laporan/penjualan/data-gudang-area',
    meta: { title: 'Data Gudang Per Area' },
    component: () => import('./views/laporan/penjualan/data-gudang-per-area.vue'),
  },
  {
    path: '/laporan/helpdesk/failed-sync-distributor',
    meta: { title: 'Failed Synchronize Distributor' },
    component: () => import('./views/laporan/helpdesk/failed-sync-distributor.vue'),
  },
  {
    path: '/laporan-aksestoko/register-aksestoko',
    meta: { title: 'Laporan Registrasi Aksestoko' },
    component: () => import('./views/laporan-aksestoko/register-aksestoko.vue'),
  },
  {
    path: '/laporan/audit-trail',
    meta: { title: 'Audit Trail' },
    component: () => import('./views/laporan/audit-trail'),
  },
  {
    path: '/laporan/helpdesk/registrasi-program-apresiasi-toko',
    meta: { title: 'Laporan Registrasi Program Apresiasi Toko 2022' },
    component: () => import('./views/laporan/helpdesk/registrasi-program-apresiasi-toko.vue'),
  },
  {
    path: '/laporan-aksestoko/register-aksestoko',
    meta: { title: 'Laporan Registrasi Aksestoko' },
    component: () => import('./views/laporan-aksestoko/register-aksestoko.vue'),
  },
]
